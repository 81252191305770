import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// px2rem 自适应
// import 'lib-flexible'
// import '@/assets/js/flexible'
import VueCoreVideoPlayer from 'vue-core-video-player'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// 滚动动画 wow.js
import { WOW } from 'wowjs'
import echarts from 'echarts'
// 第一个是videoJs的样式，后一个是vue-video-player的样式，因为考虑到我其他业务组件可能也会用到视频播放，所以就放在了main.js内
Vue.use(VueAwesomeSwiper)
Vue.prototype.$echarts = echarts
Vue.prototype.$BaseUrl = 'https://www.quanchengpeizhen.com/'
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})

Vue.use(VueCoreVideoPlayer)
// 动画 animate.css
require('animate.css/animate.min.css')
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default  需要执行动画的元素的 class
  animateClass: 'animated', // default  animation.css 动画的 class
  offset: 150, // default  距离可视区域多少开始执行动画
  mobile: false, // default  是否在移动设备上执行动画
  live: false, // 异步加载的内容是否有效

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

  callback: function (box) {
    console.log('WOW: animating <' + box.tagName.toLowerCase() + '>')
  }
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title
  }
})

// import api from '../src/utils/index'
Vue.config.productionTip = false
Vue.use(ElementUI)
// Vue.prototype.$api = api
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
