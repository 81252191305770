import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'index',

    component: () => import('../views/Index.vue'),
    meta: {
      title: '全程陪诊—首页'
    }
  },
  {
    path: '/look',
    name: 'look',

    component: () => import('../views/look.vue'),
    meta: {
      title: '全程陪诊—走进全程陪诊'
    }
  },
  {
    path: '/news',
    name: 'news',

    component: () => import('../views/news.vue'),
    meta: {
      title: '全程陪诊—新闻中心'
    }
  },
  {
    path: '/productions',
    name: 'productions',

    component: () => import('../views/productions.vue'),
    meta: {
      title: '全程陪诊—业务介绍'
    }
  },
  {
    path: '/partner',
    name: 'partner',

    component: () => import('../views/partner.vue'),
    meta: {
      title: '全程陪诊—联系与合作'
    }
  }
]

const router = new VueRouter({

  routes
})

export default router
